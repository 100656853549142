<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import logo_mock from '@/assets/logo_mock.svg';
import DesktopNav from '@/components/common/header/DesktopNav.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PrimeSidebar } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const route = useRoute();
const { isAuthorized } = storeToRefs(useAuthStore());

const authStepsPages = computed(() => {
	return route.name && route.meta.withoutHeader;
});
</script>

<template>
	<PrimeSidebar v-if="!authStepsPages" class="hc-sidebar">
		<div class="app-sidebar-logo">
			<router-link :to="{ name: 'home' }">
				<img :src="logo_mock" alt="Logo" />
			</router-link>
		</div>
		<div>
			<DesktopNav
				v-if="isAuthorized"
				class="app-header__col app-header__col--desktop-menu"
			/>
		</div>
	</PrimeSidebar>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.p-sidebar.hc-sidebar {
	width: 15.625rem !important;
	display: none;
	top: 0.625rem;
	left: 0.625rem !important;
	bottom: 1.25rem;
	height: calc(100vh - 1.875rem);
	border-radius: 1.5rem;
	border: 1px solid var(--extra-dark-green-2);
	background: linear-gradient(149deg, #111613 3.25%, #030b06 81.41%);
	box-shadow:
		0px -15.111px 15.111px 0px #070c09 inset,
		0px 15.111px 15.111px 0px #0c1410 inset;
	padding: 1.5rem 0.5rem;

	@include breakpoint(tablet) {
		display: block;
	}

	.app-sidebar-logo {
		display: flex;
		justify-content: center;
		margin-bottom: 3.375rem;

		img {
			width: auto;
			height: 2.5rem;
		}
	}
}
</style>
