<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	CLICK_TO_OPEN_STREAK,
	CURRENT_STREAK,
	DAYS,
} from '@/locales/constants';
import {
	PrimeText,
	PrimeOverlayPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import money_coin_yellow from '@/assets/money-coin-yellow.svg';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { streaks } = storeToRefs(useUserStore());
const { isMobile, isTablet } = useScreenSize();
const userStrekOverlay = ref(false);
const userStreakWidget = ref(null);

const showStreakInfo = () => {
	if (isMobile.value || isTablet.value) return;
	userStrekOverlay.value = true;
};

const hideStreakInfo = () => {
	userStrekOverlay.value = false;
};
</script>

<template>
	<div
		ref="userStreakWidget"
		class="user-streak-widget"
		@mouseover="showStreakInfo"
		@mouseleave="hideStreakInfo"
	>
		<img :src="money_coin_yellow" alt="money coin" />
		<div class="user-streak-widget-right">
			<PrimeText :size="isMobile ? 'xs' : 'base'" color="grey-500">
				{{ $t(CURRENT_STREAK) }}
			</PrimeText>
			<PrimeText :size="isMobile ? 'sm' : 'lg'" weight="600" color="white">
				{{ streaks }} {{ $t(DAYS) }}
			</PrimeText>
		</div>
		<PrimeOverlayPanel
			v-if="!isMobile || !isTablet"
			id="streak-info"
			:reference-el="userStreakWidget"
			:is-open="userStrekOverlay"
			@update="userStrekOverlay = false"
		>
			<PrimeText weight="400" color="inherit">
				{{ $t(CLICK_TO_OPEN_STREAK) }}
			</PrimeText>
		</PrimeOverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.user-streak-widget {
	display: flex;
	gap: 0.5rem;
	cursor: pointer;

	img {
		width: 2.125rem;
		height: 2.125rem;
		object-fit: cover;
		flex: none;
	}

	.user-streak-widget-right {
		display: flex;
		flex-direction: column;
		gap: 0.125rem;
	}
	@include breakpoint(tablet) {
		flex-direction: column;
		align-items: center;
		.user-streak-widget-right {
			align-items: center;
		}
	}
}

#streak-info {
	min-width: 14rem;
	text-align: center;
}
</style>
