<script lang="ts" setup>
import { ref } from 'vue';
import { deleteUser } from '@/api';
import type { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types';
import useVuelidate from '@vuelidate/core';
import {
	DELETE,
	DELETE_ACCOUNT,
	DELETE_ACCOUNT_INFORMATION,
	DELETE_ACCOUNT_QUESTION,
	PASSWORD,
} from '@/locales/constants';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRouter } from 'vue-router';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import ResetPasswordButton from '@/components/common/ResetPasswordButton.vue';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { PiTrashDuotoneThin } from '@primeinsightsgroupllc/prime-icons';

type DeleteAccountForm = {
	password: string;
};

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const componentName = useComponentName();

const router = useRouter();

const form = ref<DeleteAccountForm>({
	password: '',
});

const isLoading = ref(false);
const serverValidationResults = ref();

const { password } = useValidationRules();
const validation = useVuelidate({ password }, form, {
	$autoDirty: false,
	$externalResults: serverValidationResults,
});

const {
	validate,
	getValidationFieldStatus,
	getValidationFieldText,
	isValidationError,
	clear,
} = useValidationUtils<DeleteAccountForm>(validation);

const handleDeleteAccount = async () => {
	clear();
	await validate();
	if (isValidationError.value) return;
	try {
		isLoading.value = true;
		const { status } = await deleteUser(form.value.password);
		if (status === 200) {
			await router.replace('logout');
			emit('close-modal');
		}
	} catch (error) {
		const errorData = (error as AxiosError).response?.data as ErrorResponse;
		serverValidationResults.value = { password: errorData?.message };
		sendErrorInfo(error, componentName);
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(DELETE_ACCOUNT) }}</template>
		<template #icon>
			<PiTrashDuotoneThin color="base-primary" size="5.875rem" />
		</template>
		<template #title>{{ $t(DELETE_ACCOUNT_QUESTION) }}</template>
		<template #message>{{ $t(DELETE_ACCOUNT_INFORMATION) }}</template>
		<template #inputs>
			<PrimeInput
				v-model="form.password"
				type="password"
				:placeholder="$t(PASSWORD)"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>
			<ResetPasswordButton @click="emit('close-modal')" />
		</template>
		<template #actions>
			<PrimeButton
				:label="$t(DELETE)"
				@click="handleDeleteAccount"
			/>
			<PrimeLoader v-if="isLoading" />
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

:deep(.content-title),
:deep(.content-message) {
	.p-text {
		max-width: 90%;
		@include breakpoint(mobile) {
			max-width: 75%;
		}
	}
}
:deep(.content-inputs) {
	margin-top: 0.75rem;
}
</style>
