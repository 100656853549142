<script setup lang="ts">
import {
	REWARD_SENT,
	REWARD_RECEIVE_TIME,
	GOTCHA,
	CONGRATS,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import money_box_logo from '@/assets/money_box_mock.svg';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CONGRATS) }}</template>
		<template #icon>
			<img :src="money_box_logo" alt="money box logo" />
		</template>
		<template #title>{{ $t(REWARD_SENT) }}</template>
		<template #message>{{ $t(REWARD_RECEIVE_TIME) }}</template>
		<template #actions>
			<PrimeButton :label="$t(GOTCHA)" @click="handleCloseModal" />
		</template>
	</ModalCommonContent>
</template>
