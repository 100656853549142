<script lang="ts" setup>
import { ref } from 'vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useAuthStore } from '@/stores/auth';
import { useComponentName } from '@/utils/composables/useComponentName';
import {
	RESEND,
	RESET_PASSWORD,
	RESET_PASSWORD_SENT_DETAILS,
	RESET_PASSWORD_SENT_MESSAGE,
	RESET_PASSWORD_SENT_SPAM,
} from '@/locales/constants';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeDivider,
	PrimeText,
	PrimeCooldownButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const COOL_DOWN_SECONDS = 15;

defineEmits<{
	(e: 'close-modal'): void;
}>();

const loading = ref(false);
const cooldownButtonEl = ref<{ restartTimer: () => void } | null>(null);

const { email } = storeToRefs(useUserStore());
const authStore = useAuthStore();
const componentName = useComponentName();

const resendResetPassword = async () => {
	try {
		loading.value = true;
		const response = await authStore.forgotPassword(email.value!);

		if (response?.status === 200) {
			cooldownButtonEl.value?.restartTimer();
			notify({ body: response?.data.message });
		} else {
			notify({ body: authStore.error?.message || '' });
		}
	} catch (error) {
		notify({ body: authStore.error?.message || '' });
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(RESET_PASSWORD) }}</template>
		<template #icon>
			<PiEnvelopeDuotoneThin color="base-primary" size="5.875rem" />
		</template>
		<template #title>
			<PrimeText tag="div" weight="500" class="password-sent-email">
				<span v-html="$t(RESET_PASSWORD_SENT_MESSAGE, { email })" />
			</PrimeText>
		</template>
		<PrimeText tag="div" size="sm" class="password-sent details">
			{{ $t(RESET_PASSWORD_SENT_DETAILS) }}
		</PrimeText>
		<PrimeDivider top-gap="0.75rem" />
		<PrimeText tag="div" size="xs" class="password-sent spam-info">
			{{ $t(RESET_PASSWORD_SENT_SPAM) }}
		</PrimeText>
		<template #actions>
			<PrimeCooldownButton
				ref="cooldownButtonEl"
				:text="$t(RESEND)"
				:timer="COOL_DOWN_SECONDS"
				@click="resendResetPassword"
			/>
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.password-sent-email {
	:deep(.user-email) {
		word-break: break-word;
	}
}

.password-sent {
	margin-top: 0.75rem;
	text-align: center;
}
</style>
