import { useAuthStore } from '@/stores/auth';
import { AUTH_ROUTE_NAME, USER_ROUTE_NAME } from '@/constants/routes';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { cidStorage } from '@/utils/local-storage';
import { useRewardsStore } from '@/stores/rewards';
import { storeToRefs } from 'pinia';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user';

export const checkUserEmailHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const forgotPasswordHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.resetPasswordSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSignUpCompleteHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.registrationEmailSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkExistingSocialsHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.socials.length) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSocialAuthHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.userSocialAuthData) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkResetPasswordHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	if (!to.query.email && !to.query.token) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSurveyClickIdHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const cid = to.query.cid;
	if (cid) {
		cidStorage.value = cid.toString();
		delete to.query.cid;
		delete to.query.tx_id;
		next({ path: to.path, query: to.query });
	} else {
		next();
	}
};

export const checkInitializedClaim = () => {
	const rewardsStore = useRewardsStore();
	if (!rewardsStore.isClaimInitialized) {
		return { name: USER_ROUTE_NAME.SURVEYS };
	}
};

export const checkEmailSubscription = async (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { isAuthorized } = storeToRefs(useAuthStore());
	if (!isAuthorized.value) {
		to.meta.withoutHeader = true;
	}

	next();
};

export const showAdjoeOfferwall = async (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { isAdjoeEnabled } = storeToRefs(useAppStore());
	const { isUserAdjoeEnabled } = storeToRefs(useUserStore());

	if (!isAdjoeEnabled.value || !isUserAdjoeEnabled.value) return false;

	const { isAndroid, isTablet } = useMobileApp();

	if (isAndroid.value && !isTablet()) {
		await useAppStore().showAdjoeOfferwall();
		return false;
	}

	next();
};

export const checkHomePageAvailability = async (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const userStore = useUserStore();
	const { isHomepageAvailable, isFeaturesLoading } = storeToRefs(userStore);

	if (!userStore.features) {
		try {
			if (isFeaturesLoading.value) {
				await userStore.waitForUserFeatures();
			} else {
				await userStore.fetchFeatures();
			}
		} catch (error) {
			return next({ name: USER_ROUTE_NAME.SURVEYS, query: to.query });
		}
	}

	if (!isHomepageAvailable.value) {
		return next({ name: USER_ROUTE_NAME.SURVEYS, query: to.query });
	}

	next();
};
