<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRouter } from 'vue-router';
import {
	CHECK_IT_OUT,
	CONGRATS,
	LEADERBOARD_SCORED_TITLE,
	LEADERBOARD_UNLOCKED_MESSAGE,
	LEADERBOARD_UNLOCKED_TITLE,
	LEADERBOARD_WON_MESSAGE,
	WELL_DONE,
} from '@/locales/constants';
import { LeaderboardTypes } from '@/enums';
import ChangeUsernameForm from '@/components/forms/ChangeUsernameForm.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import unlock_leaderboard from '@/assets/unlock_leaderboard.svg';

type Options = {
	type: LeaderboardTypes;
	coins?: number;
};

const router = useRouter();
const { username } = storeToRefs(useUserStore());

defineProps<{
	options: Options;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const goToleaderboard = async () => {
	router.push({ name: 'leaderboard' });
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent>
		<template #header>
			<template v-if="options.type === LeaderboardTypes.SCORED">
				{{ $t(WELL_DONE) }}
			</template>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">
				{{ $t(CONGRATS) }}
			</template>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">
				{{ $t(CONGRATS) }}
			</template>
		</template>
		<template #icon>
			<img :src="unlock_leaderboard" alt="" />
		</template>
		<template #title>
			<PrimeText
				v-if="options.type === LeaderboardTypes.UNLOCKED"
				tag="div"
				weight="500"
				color="white"
			>
				{{ $t(LEADERBOARD_UNLOCKED_TITLE) }}
			</PrimeText>
			<PrimeText
				v-if="options.type === LeaderboardTypes.SCORED"
				tag="div"
				weight="500"
				color="white"
			>
				{{ $t(LEADERBOARD_SCORED_TITLE) }}
			</PrimeText>
		</template>
		<template #message>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED && !username">
				{{ $t(LEADERBOARD_UNLOCKED_MESSAGE) }}
			</template>

			<PrimeText
				v-if="options.type === LeaderboardTypes.SCORED"
				tag="div"
				weight="600"
				color="white"
				class="message-locked"
			>
				{{
					$t(LEADERBOARD_WON_MESSAGE, {
						value: localizePoints(options.coins || 0),
					})
				}}
			</PrimeText>
		</template>
		<template #actions>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">
				<ChangeUsernameForm v-if="!username" :handler="goToleaderboard" />
				<PrimeButton
					v-else
					:label="$t(CHECK_IT_OUT)"
					@click="goToleaderboard"
				/>
			</template>
			<template v-if="options.type === LeaderboardTypes.SCORED">
				<PrimeButton :label="$t(CHECK_IT_OUT)" @click="goToleaderboard" />
			</template>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
#heycash-modal {
	:deep(.content-title) {
		justify-content: flex-start;
		margin-top: 1.5rem;

		.p-text {
			text-align: left;
		}
		& > div > span > span {
			display: inline-flex;
			align-items: center;
			width: 2rem;
			height: 2rem;
		}
	}

	:deep(.content-message) {
		justify-content: flex-start;
		text-align: left;
	}
}
</style>
