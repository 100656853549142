<script lang="ts" setup>
import { onMounted, ref, watch, computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import AppMobileNav from '@/components/AppMobileNav.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import {
	PrimeLoaderSpinner,
	PrimeNotificationsContainer,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { getInitialUserLocalization } from '@/utils/helpers';
import { handleUserLocale } from '@/i18n';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import NoConnection from '@/components/NoConnection.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppRerender } from '@/utils/composables/useAppRerender';
import { useBlockScroll } from '@/utils/composables/useBlockScroll';
import { useCapacitorPlugins } from '@/utils/composables/useCapacitorPlugins';
import { providePrimeSurveys } from '@/utils/composables/useIntegrationScriptRender';
import { providePrimeIcons } from '@primeinsightsgroupllc/prime-icons';
import '@primeinsightsgroupllc-ui/prime-ui-kit/dist/main.css';
import { providePrimeTheme } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useTranslateDetection } from '@/utils/composables/useTranslateDetection';
import { useAppPusherEventListener } from '@/utils/composables/app/useAppPusherEventListener';
import { useAppNotificationListener } from '@/utils/composables/app/useAppNotificationListener';
import { useAppInit } from '@/utils/composables/app/useAppInit';
import { useAppAnnouncementsListener } from '@/utils/composables/app/useAppAnnouncementsListener';
import { providePrimeChat } from '@/utils/composables/useIntegrationChatRender';
import PrimeChat from '@/components/PrimeChat.vue';
import { useUserStore } from '@/stores/user';
import { initHotjar } from '@/utils/hotjar';
import { onUnmounted } from 'vue';
import {
	THEME_DARK_APP,
	THEME_ICONS_DARK_APP,
	THEME_PALETTE,
} from '@/constants/theme';
import AppSidebar from '@/components/sidebar/AppSidebar.vue';
import AppSidebarRight from '@/components/sidebar/AppSidebarRight.vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useAppStore } from '@/stores/app';
import { useLiveChat } from '@/utils/composables/useLiveChat';
import {
	PrimeModalWindow,
	useModalStorage,
} from '@primeinsightsgroupllc-modals/prime-modals';
import { MODAL_MAP } from './constants/modals-map';
import { AUTH_ROUTE_NAME } from '@/constants/routes';

const { setThemeColors } = providePrimeTheme();
const themeContainer = ref<HTMLElement | undefined>(undefined);

setThemeColors(THEME_DARK_APP, themeContainer.value, THEME_PALETTE);

const { setIconsColors } = providePrimeIcons();

setIconsColors(THEME_ICONS_DARK_APP);

providePrimeSurveys();
providePrimeChat();
useBlockScroll();
useTranslateDetection();

const { localizationCookie } = useAppCookies();
const route = useRoute();
const { handleRouteChange, shiftModal } = useModalStorage();
const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);
const { isMobileApp, isIos } = useMobileApp();
const { isDesktop } = useScreenSize();
const { appKey } = useAppRerender();
const { init } = useAppInit();

const {
	isOnline,
	initCapacitor,
	deinitCapacitor,
	setupResumeListener,
	setupPauseListener,
} = useCapacitorPlugins();

const { isInitialDataLoading } = storeToRefs(useUserStore());
const appStore = useAppStore();
const { isPrimeChatEnabled } = useLiveChat();

useAppNotificationListener();
useAppAnnouncementsListener();

const { initPusherConnection, removePusherConnection } =
	useAppPusherEventListener();

const isMobileNavShown = ref(false);
const localizationLoading = ref(false);

setupResumeListener(async () => {
	if (init && route.name !== AUTH_ROUTE_NAME.SIGNATURE_LOGIN) {
		await init();
	}
	await initPusherConnection();
});

setupPauseListener(async () => {
	removePusherConnection();
});

onMounted(async () => {
	await initCapacitor();
	let localization = localizationCookie;

	if (!localization) {
		localizationLoading.value = true;
		try {
			localization = await getInitialUserLocalization();
		} catch (e) {
			sendErrorInfo(e);
		} finally {
			localizationLoading.value = false;
		}
	}

	await handleUserLocale(localization);
});

watch([isAuthorized, route], (data) => {
	const [isAuthorized, route] = data;
	const isVisible = Boolean(isAuthorized && !route.meta.hideMobileNav);

	if (isMobileNavShown.value !== isVisible) {
		isMobileNavShown.value = isVisible;
		appKey.value = appKey.value + 1;
	}
});

const isAuthPage = computed(() => {
	const routeName = route?.name;
	return (
		typeof routeName === 'string' &&
		Object.values(AUTH_ROUTE_NAME).includes(routeName)
	);
});

const isIntegrationPage = computed(
	() => route.name && route.meta?.integrationPage
);

const showSideBars = computed(
	() => route.name && !isAuthPage.value && isDesktop.value
);

onMounted(() => {
	// Close active modal if page reload or tab close
	if (!isMobileApp) {
		initHotjar();
		window.addEventListener('beforeunload', shiftModal);
	}
});

onUnmounted(() => {
	deinitCapacitor();
	appStore.clearLog();
});

// Close all modals if route change
watch(
	() => route.name,
	async (newRoute, prevRoute) => {
		if (newRoute !== prevRoute) {
			shiftModal();
		}
	}
);

watch(
	() => route.name,
	async () => await handleRouteChange(route.name as string)
);
</script>

<template>
	<div ref="themeContainer">
		<ErrorBoundary>
			<div class="app-layout">
				<div v-if="!isOnline" class="app-content full-page">
					<NoConnection />
				</div>
				<PrimeLoaderSpinner
					v-if="localizationLoading || isInitialDataLoading"
				/>
				<template v-else>
					<!-- <AppHeader /> -->
					<div v-if="isIos" class="fixed-filled-top-for-ios"></div>
					<div
						:key="appKey"
						class="app-content"
						:class="{
							'auth-layout': isAuthPage,
							'integration-page': isIntegrationPage,
						}"
					>
						<AppSidebar v-if="showSideBars" />
						<AppSidebarRight v-if="showSideBars" />
						<RouterView />
					</div>
					<PrimeNotificationsContainer />
					<!-- Modals -->
				</template>
				<PrimeModalWindow :modals="MODAL_MAP" />
				<AppMobileNav v-if="!localizationLoading && isMobileNavShown" />
				<!--TODO: Refactor to reinit resize observer as exposed method -->
				<PrimeChat v-if="isPrimeChatEnabled" :key="appKey" />
			</div>
		</ErrorBoundary>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.fixed-filled-top-for-ios {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: env(safe-area-inset-top);
	background-color: #0a0a0a;
	z-index: 99;
	height: 0px;
	overflow: hidden;
}

.app-layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	min-height: 100svh;
	overflow: auto;
	position: relative;

	@include breakpoint(tablet) {
		min-height: 100vh;
	}

	& .app-content {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		flex: 1 0 auto;
		overflow: auto;
		padding: calc(0.75rem + env(safe-area-inset-top)) 0.75rem 0.75rem;

		&.full-page {
			height: 100vh;
			width: 100%;
			position: absolute;
			z-index: 10000000;
			flex: 1 1 100%;
			max-width: initial;
			background-color: var(--base-primary-03);
		}

		&.auth-layout {
			padding: 0;
			padding-top: env(safe-area-inset-top);
			flex: 1 0 auto;
		}

		&.integration-page {
			padding: env(safe-area-inset-top) 0 0 0;
		}

		@include breakpoint(tablet) {
			padding: calc(0.625rem + env(safe-area-inset-top)) 17.75rem 0.625rem;
			&.integration-page {
				padding: calc(0.625rem + env(safe-area-inset-top)) 17.75rem 0.625rem;
			}
		}
	}

	:deep(.p-skeleton) {
		background-color: var(--grey-200);
	}
}
</style>
