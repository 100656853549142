<script lang="ts" setup>
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { NEW_PASSWORD_SENT } from '@/constants/modals';
import { useAuthStore } from '@/stores/auth';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { NEED_NEW_PASSWORD } from '@/locales/constants';

const emit = defineEmits<{
	(e: 'click'): void;
}>();

const loading = ref(false);

const componentName = useComponentName();
const authStore = useAuthStore();
const { email } = storeToRefs(useUserStore());
const { createNewModal } = useModalStorage();

const handleClick = async () => {
	try {
		loading.value = true;
		const response = await authStore.forgotPassword(email.value!);

		if (response?.status === 200) {
			await createNewModal(NEW_PASSWORD_SENT, { useDefaultClose: true });
			notify({
				body: response.data.message,
			});
			emit('click');
		} else {
			notify({ body: authStore.error?.message || '' });
		}
	} catch (error) {
		notify({ body: authStore.error?.message || '' });
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<button
		role="button"
		class="need-password-button"
		@click.prevent="handleClick"
	>
		{{ $t(NEED_NEW_PASSWORD) }}
	</button>
	<PrimeLoader v-if="authStore.loading" />
</template>

<style lang="scss">
.need-password-button {
	cursor: pointer;
	display: block;
	color: var(--base-primary);
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.4;
	width: max-content;
	margin: 0.25rem auto 0 0;
	background: transparent;
	border: none;

	&:hover {
		text-decoration: underline;
		color: var(--grey-700);
	}
}
</style>
