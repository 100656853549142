import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted, computed } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE } from '@/locales/constants';
import {
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type {
	UserLevel,
	UserStreak,
	OfferwallRewardEvent,
	UserBalanceUpdateInfo,
	OfferwallTransactionDelayed,
	FeatureFlagsData,
} from '@/types';
import { MessageChannels, TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';
import { useMessagesStore } from '@/stores/messages';
import { useAppReviewPrompt } from '@/utils/composables/useInAppReview';

export const useAppPusherEventListener = (): {
	initPusherConnection: () => Promise<void>;
	removePusherConnection: () => void;
} => {
	const { createNewModal } = useModalStorage();
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const userStore = useUserStore();
	const appStore = useAppStore();
	const messagesStore = useMessagesStore();
	const userAndToken = computed(() => userId.value + userAuthToken.value);
	const { showInAppReviewPrompt } = useAppReviewPrompt();

	const removePusherConnection = () => {
		echo.removeConnections();
	};

	const initPusherConnection = async () => {
		if (!userId.value || !userAuthToken.value) return;

		channelName.value = `user-notifications-${userId.value}`;
		if (echo.isCurrentConnection(channelName.value, userAuthToken.value)) {
			return;
		}

		removePusherConnection();

		try {
			await echo.createConnection(userAuthToken.value);
		} catch (error) {
			console.error('Error while creating WebSocket connection:', error);
		}

		window.Echo.private(channelName.value)
			.listen(
				'.offerwall_transaction_delayed',
				(event: OfferwallTransactionDelayed) => {
					createNewModal(OFFERWALLS_WELL_DONE_MODAL, {
						name: OFFERWALLS_WELL_DONE_MODAL,
						data: event,
						options: {
							...event,
							id: event.notification_id,
							channel: MessageChannels.NOTIFICATION,
						},
						onClose: () =>
							messagesStore.readNotification(event.notification_id),
					});
					userStore.fetchOfferwallPending();
				}
			)
			.listen(
				// Balance update
				'.balance-updated',
				async (event: UserBalanceUpdateInfo) => {
					await userStore.fetchUserData();
					userStore.setCollectedCoins(event.new_balance);
					if (!event.is_silent) {
						notify({ body: t(UPDATED_BALANCE) });
					}
					if (event.increase_offerwalls_progress) {
						userStore.increaseOfferwallsMetaProgress();
					}
					if (!event.is_silent && event.transaction_type === 1) {
						await showInAppReviewPrompt();
					}
				}
			)
			.listen(
				// Level update
				'.user-survey-taken',
				(event: UserLevel) => {
					userStore.setLevel(event);
				}
			)
			.listen(
				// Level update
				'.user-level-up',
				(event: UserLevel) => {
					userStore.setLevel(event);
					createNewModal(USER_LEVEL_MODAL, { ...event });
				}
			)
			.listen(
				// Streak update
				'.user-streak-updated',
				(event: UserStreak) => {
					const userStreakLength = userStore.streaks;
					userStore.setStreak(event);
					if (userStreakLength === 0 && event.length === 1) {
						createNewModal(USER_STREAK_MODAL);
					}
				}
			)
			.listen(
				'.offerwall_transaction_completed',
				async (event: OfferwallRewardEvent) => {
					userStore.setCollectedCoins(event.new_balance);
					await userStore.fetchOfferwallPending();
					createNewModal(OFFERWALLS_REWARD_NOTIFICATION_MODAL, {
						...event,
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						onClose: () =>
							messagesStore.readNotification(event.notification_id),
					});
					await showInAppReviewPrompt();
				}
			)
			.listen('.offerwalls_unlocked', () => {
				userStore.fetchFeatures();
			})
			.listen('.feature-flags-update', (event: FeatureFlagsData) => {
				userStore.updateFeatures(event);
			})

			.listen(
				'.gtm_track_event',
				async ({
					event_name,
					event_name_adjust,
					params,
				}: {
					event_name: TrackingEvents;
					event_name_adjust: string;
					params: Record<string, any>;
				}) => {
					appStore.addLog(
						`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
					);
					await appStore.trackEvent(event_name, params, event_name_adjust);
				}
			);
	};

	watch(
		userAndToken,
		async () => {
			await initPusherConnection();
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		removePusherConnection();
	});

	return {
		initPusherConnection,
		removePusherConnection,
	};
};
