<script lang="ts" setup>
import {
	CONGRATS,
	GOTCHA,
	STREAK_GONGRATS_TITLE,
	STREAK_GONGRATS_DESCRIPTION,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { localizePoints } from '@/utils/helpers';
import { PiFireDuotoneThin } from '@primeinsightsgroupllc/prime-icons';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const MAX_STREAK_PRIZE = 1000;
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CONGRATS) }}</template>
		<template #icon>
			<PiFireDuotoneThin size="5.875rem" color="base-secondary" />
		</template>
		<template #title>{{ $t(STREAK_GONGRATS_TITLE) }}</template>
		<template #message>
			{{
				$t(STREAK_GONGRATS_DESCRIPTION, {
					value: localizePoints(MAX_STREAK_PRIZE),
				})
			}}
		</template>
		<template #actions>
			<PrimeButton  :label="$t(GOTCHA)" @click="handleCloseModal" />
		</template>
	</ModalCommonContent>
</template>
