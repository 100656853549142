<script lang="ts" setup>
import {
	CONFIRM_CLAIM_MODAL_TITLE,
	CONFIRM_CLAIM_MODAL_MESSAGE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { email } = storeToRefs(useUserStore());
</script>

<template>
	<div class="confirm-claim-modal">
		<ModalCommonContent>
			<template #header>{{ $t(CONFIRM_CLAIM_MODAL_TITLE) }}</template>
			<template #icon>
				<PiEnvelopeDuotoneThin color="base-primary" size="5.5rem" />
			</template>
			<template #message>
				<PrimeText tag="div" size="sm" align="center">
					<span v-html="$t(CONFIRM_CLAIM_MODAL_MESSAGE, { email })" />
				</PrimeText>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.confirm-claim-modal {
	:deep(.content-message) {
		max-width: 80%;
		margin: 0 auto;
	}
}
</style>
