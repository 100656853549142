<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { BALANCE, POINTS } from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { useI18n } from 'vue-i18n';
import {
	PrimeText,
	PrimeOverlayPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import money_coin_green from '@/assets/money-coin-green.svg';

const { isMobile, isTablet } = useScreenSize();
const { t } = useI18n();
const { collectedCoins, currencyBalance, currency } =
	storeToRefs(useUserStore());

const userBalanceOverlay = ref(false);
const userBalanceWidget = ref(null);

const balanceText = computed(() => {
	return `${collectedCoins.value} ${t(POINTS)} ≈ ${transformCurrency(
		+currencyBalance.value!,
		currency.value,
		2
	)}`;
});

const showUserBalanceInfo = () => {
	userBalanceOverlay.value = true;
};

const hideUserBalanceInfo = () => {
	if (isTablet.value || isMobile.value) return;
	userBalanceOverlay.value = false;
};
</script>

<template>
	<div
		ref="userBalanceWidget"
		class="user-balance-widget"
		@mouseover="showUserBalanceInfo"
		@mouseleave="hideUserBalanceInfo"
	>
		<img :src="money_coin_green" alt="money coin" />
		<div class="user-balance-widget-right">
			<PrimeText :size="isMobile ? 'xs' : 'base'" color="grey-500">
				{{ $t(BALANCE) }}
			</PrimeText>

			<PrimeText :size="isMobile ? 'sm' : 'lg'" weight="600" color="white">
				{{ localizePoints(collectedCoins) }} {{ $t(POINTS) }}
			</PrimeText>
		</div>
		<PrimeOverlayPanel
			id="balance-info"
			:reference-el="userBalanceWidget"
			:is-open="userBalanceOverlay"
			@update="userBalanceOverlay = false"
		>
			<PrimeText weight="400" color="inherit" :size="isMobile ? 'xs' : 'base'">
				{{ balanceText }}
			</PrimeText>
		</PrimeOverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.user-balance-widget {
	display: flex;
	gap: 0.5rem;
	cursor: pointer;

	.user-balance-widget-right {
		display: flex;
		flex-direction: column;
		gap: 0.125rem;
	}

	img {
		width: 2.125rem;
		height: 2.125rem;
		object-fit: cover;
		flex: none;
	}
	@include breakpoint(tablet) {
		flex-direction: column;
		align-items: center;
		.user-balance-widget-right {
			align-items: center;
		}
	}
}

#balance-info {
	width: 8rem;
	@include breakpoint(mobile) {
		min-width: 14rem;
	}
	text-align: center;
}
</style>
