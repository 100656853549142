<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { REFFERAL_LINK, COPY, COPY_INTO_CLIPBOARD } from '@/locales/constants';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const userStore = useUserStore();
const { refLink } = storeToRefs(userStore);

const handleCopy = () => {
	navigator.clipboard.writeText(refLink.value);
	notify({
		body: `${t(COPY_INTO_CLIPBOARD)}: ${refLink.value}`,
	});
};
</script>

<template>
	<ModalCommonContent class="refferal-link-modal">
		<template #header>{{ $t(REFFERAL_LINK) }}</template>
		<PrimeText weight="500" color="white" class="refferal-link-teaser">
			{{ refLink }}
		</PrimeText>
		<template #actions>
			<PrimeButton
				:label="$t(COPY)"
				role="submit"
				transform="capitalize"
				@click="handleCopy"
			/>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.refferal-link-teaser {
	display: inline-block;
	text-align: center;
}
</style>
