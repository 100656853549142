<script setup lang="ts">
import { useSlots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';

const slots = useSlots();
</script>

<template>
	<PrimeModalLayout id="heycash-modal" class="heycash-modal">
		<template #title>
			<PrimeText
				v-if="slots.header"
				tag="p"
				size="base"
				weight="500"
				color="white"
				class="content-header"
			>
				<slot name="header" />
			</PrimeText>
		</template>
		<template #content>
			<span v-if="slots.icon" class="content-icon scale-in">
				<slot name="icon" />
			</span>

			<div v-if="slots.title" class="content-title">
				<PrimeText tag="p" weight="500" color="white" size="1xl">
					<slot name="title" />
				</PrimeText>
			</div>

			<PrimeText
				v-if="slots.description"
				tag="p"
				size="base"
				color="inherit"
				weight="500"
				class="content-description"
			>
				<slot name="description" />
			</PrimeText>

			<div v-if="slots.message" class="content-message">
				<PrimeText tag="p" color="inherit" weight="500" size="base">
					<slot name="message" />
				</PrimeText>
			</div>

			<div v-if="slots.inputs" class="content-inputs">
				<slot name="inputs" />
			</div>

			<slot name="default" />
		</template>

		<template v-if="slots.actions || slots.raw" #bottom>
			<div
				v-if="slots.actions"
				class="content-actions"
				:class="{
					'inputs-actions': slots.inputs,
				}"
			>
				<slot name="actions" />
			</div>

			<div v-if="slots.raw">
				<slot name="raw" />
			</div>
		</template>
	</PrimeModalLayout>
</template>

<style lang="scss">
.heycash-modal {
	.content-icon {
		display: flex;
		justify-content: center;
		margin-bottom: 0.75rem;
	}

	.content-icon img {
		display: inline-block;
		vertical-align: top;
	}

	.content-icon svg {
		color: white;
	}

	.content-title {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.content-description {
		border-bottom: 1px solid var(--grey-200);
		margin-bottom: 0.75rem;
		padding-bottom: 0.75rem;
		width: 100%;
		color: var(--grey-cool-50);
	}

	.content-message {
		display: flex;
		justify-content: center;
		text-align: center;
		width: 100%;
		color: var(--grey-cool-50);
	}

	.content-inputs {
		width: 100%;

		& .p-input-wrapper:not(:first-of-type) {
			margin-top: 0.75rem;
		}
	}

	.content-actions {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
</style>
