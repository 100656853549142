<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	SET_USERNAME,
	CHANGE_USERNAME,
	USERNAME_UPDATE_SUCCESS_MESSAGE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import ChangeUsernameForm from '@/components/forms/ChangeUsernameForm.vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useI18n } from 'vue-i18n';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const userStore = useUserStore();
const { username } = storeToRefs(userStore);
const { t } = useI18n();

const handleCloseModal = () => {
	emit('close-modal');
	notify({ body: t(USERNAME_UPDATE_SUCCESS_MESSAGE) });
};
</script>

<template>
	<ModalCommonContent>
		<template #header>
			{{ username ? $t(CHANGE_USERNAME) : $t(SET_USERNAME) }}
		</template>
		<template #inputs>
			<ChangeUsernameForm :handler="handleCloseModal" />
		</template>
	</ModalCommonContent>
</template>
<style lang="scss" scoped>
#heycash-modal :deep(.change-username-form) {
	display: flex;
	flex-direction: column;
	align-items: center;
	.p-input-wrapper {
		width: 100%;
	}
	.p-btn {
		width: unset;
		margin-top: 1.5rem;
	}
}
</style>
