<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { RewardsItem } from '@/types';
import { categories } from '@/views/surveys/components/user-dashboard/rewards/categories';
import RewardItem from '@/views/surveys/components/user-dashboard/rewards/RewardItem.vue';
import { useI18n } from 'vue-i18n';
import { VIEW_MORE_REWARDS } from '@/locales/constants';
import { RewardCategories } from '@/enums';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import RewardSubCategories from '@/views/surveys/components/user-dashboard/rewards/RewardSubCategories.vue';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';

const {
	category,
	subCategories = false,
	isFiltered = false,
} = defineProps<{
	category: RewardCategories;
	subCategories?: boolean;
	isFiltered?: boolean;
}>();

const categoryItems = defineModel<RewardsItem[]>('categoryItems', {
	required: true,
});

const selectedOption = defineModel<number>('selectedOption', {
	required: true,
});

const { t } = useI18n();
const isFullListVisible = ref(false);

const expandButtonText = computed(() => {
	const itemsLeftToShow = categoryItems.value.length - 2;
	return `${t(VIEW_MORE_REWARDS, {
		value: itemsLeftToShow,
	})} ${t(categories[category].name, itemsLeftToShow).toLowerCase()}`;
});

const categoryName = computed(() => {
	const transfer = category === RewardCategories.TRANSFERS ? 1 : 2;
	return `${t(categories[category].name, transfer)} (${
		categoryItems.value.length
	})`;
});

const visibleRewards = computed(() => {
	return isFullListVisible.value
		? categoryItems.value
		: categoryItems.value.slice(0, 2);
});

watch(
	categoryItems,
	(value) => {
		isFullListVisible.value = isFiltered || value.length <= 2;
	},
	{ immediate: true }
);

const rewardsStore = useRewardsStore();
const { availableSubCategoriesContent, selectedSubCategories } =
	storeToRefs(rewardsStore);

const selectedSubCategory = ref<string>(selectedSubCategories.value[category]);

watch(selectedSubCategory, (newValue) => {
	rewardsStore.setSelectedSubCategories(category, newValue);
});
</script>

<template>
	<div class="reward-category">
		<div class="category-wrapper">
			<div class="category-header">
				<component :is="categories[category].icon" color="inherit" />
				<PrimeText size="xs" weight="500" color="inherit">
					{{ categoryName }}
				</PrimeText>
			</div>
			<RewardSubCategories
				v-if="subCategories && availableSubCategoriesContent"
				v-model:selected-sub-category="selectedSubCategory"
				:sub-categories="availableSubCategoriesContent[category]"
			/>
			<div>
				<RewardItem
					v-for="reward in visibleRewards"
					:key="reward.reward_external_id"
					:item="reward"
					:selected-option-id="selectedOption"
					@select-option="selectedOption = $event"
				/>
			</div>
			<PrimeButton
				v-if="!isFullListVisible"
				type="link"
				size="small"
				variant="primary"
				color="secondary"
				:label="expandButtonText"
				class="rewards-more-btn"
				@click="isFullListVisible = true"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.reward-category {
	background-color: rgba(35, 35, 35, 1);
	border-radius: 1.5rem;
	margin-bottom: 0.75rem;
	overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}
}
.category-header {
	display: flex;
	align-items: center;
	color: white;
	padding: 0.75rem 1rem;

	& span {
		margin-left: 0.375rem;
	}
}
:deep(.rewards-more-btn) {
	margin: 0 0 0.5rem 0.5rem;
	border-radius: 0.5rem !important;

	.p-btn-label {
		color: rgba(59, 186, 106, 1);
		border-color: rgba(59, 186, 106, 1);
	}
}

.category-wrapper {
	padding: 0 1.5rem;
}
</style>
