<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { LEVEL, POINTS } from '@/locales/constants';
import {
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import UserlevelInfoCard from './UserlevelInfoCard.vue';

const { levelActual, coinsEarned, coinsTarget, levelReward } =
	storeToRefs(useUserStore());

const progress = computed(
	() => (Number(coinsEarned.value) / Number(coinsTarget.value)) * 100
);
</script>
<template>
	<div class="user-level-widget">
		<UserlevelInfoCard :points="levelReward" />
		<div class="user-level-content">
			<PrimeText size="sm" weight="400" color="grey-900">
				{{ $t(LEVEL) }} {{ levelActual }}
			</PrimeText>
			<div>
				<PrimeText size="sm" weight="400" color="white">
					{{ localizePoints(coinsEarned) }}
				</PrimeText>
				<PrimeText size="sm" weight="400" color="grey-900">
					/{{ localizePoints(coinsTarget) }}
					{{ $t(POINTS) }}
				</PrimeText>
			</div>
		</div>
		<PrimeProgressbar :value="progress" progress-bar-rounded />
	</div>
</template>

<style scoped lang="scss">
.user-level-content {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-top: 0.75rem;
}

:deep(.p-progress) {
	margin-top: 0.5rem;
	background: #212121;

	.p-progress-bar {
		background: linear-gradient(
			270deg,
			#ffb945 0%,
			rgba(255, 185, 69, 0) 100%
		) !important;
	}
}
</style>
