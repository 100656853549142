<script lang="ts" setup>
import {
	TRANSLATE_MODAL_TITLE,
	TRANSLATE_MODAL_HEADER,
	TRANSLATE_MODAL_INFO,
	TRANSLATE_MODAL_MESSAGE,
	OPEN_ACCOUNT_SETTINGS,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiTranslateDuotone } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRouter } from 'vue-router';

const router = useRouter();
</script>

<template>
	<div class="google-translation-modal">
		<ModalCommonContent>
			<template #header>{{ $t(TRANSLATE_MODAL_HEADER) }}</template>
			<template #icon>
				<PiTranslateDuotone color="base-primary" size="5.5rem" />
			</template>
			<template #title>
				<PrimeText tag="div" weight="500">
					{{ $t(TRANSLATE_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText tag="div" size="sm" color="grey-700">
					{{ $t(TRANSLATE_MODAL_INFO) }}
				</PrimeText>
			</template>
			<template #message>
				<PrimeText tag="div" size="sm" weight="500">
					{{ $t(TRANSLATE_MODAL_MESSAGE) }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					:label="$t(OPEN_ACCOUNT_SETTINGS)"
					@click="router.push({ name: 'settings' })"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style scoped lang="scss">
.google-translation-modal {
	.content-description .p-text {
		text-align: center;
	}
}
</style>
