import { inject, type InjectionKey, provide, ref, watch } from 'vue';
import {
	cidStorage,
	maidStorage,
	showPrimeChat,
	sslhStorage,
} from '@/utils/local-storage';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { COINS } from '@/locales/constants';
import { getLanguage } from '@/utils/helpers';
import { storeToRefs } from 'pinia';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type { renderPrimeSurveyProps } from '@/types';

export const primeSurveysInjection: InjectionKey<
	ReturnType<typeof providePrimeSurveys>
> = Symbol();

export const usePrimeSurveysIntegration = () => inject(primeSurveysInjection)!;

export const providePrimeSurveys = () => {
	const { platform } = useMobileApp();
	const { localizationCookie } = useAppCookies();
	const i18n = useI18n();
	const userStore = useUserStore();
	const authStore = useAuthStore();

	const { userId, userHash, source } = storeToRefs(userStore);
	const { getAdminId } = storeToRefs(authStore);

	const language = ref(getLanguage(localizationCookie));
	const appId = ref(import.meta.env.VITE_PS_APPID);

	const integrationScriptLoading = ref(true);

	const renderPrimeSurveys = (arg: renderPrimeSurveyProps): (() => void) =>
		onUserId(() => {
			integrationScriptLoading.value = false;
			const props = {
				container: arg.container,
				module: arg.module || 'surveys',
				moduleTitle: arg.moduleTitle,
				appId: appId.value,
				userId: userId.value || '',
				language: language.value || 'en',
				clickId: cidStorage.value,
				sHash: userHash.value || '',
				currency: i18n.t(COINS),
				source: source.value || '',
				platform: platform.value,
				adminId: getAdminId.value || null,
				maid: maidStorage.value,
				enableOffers: arg.enableOffers,
				// TODO: remove it when all users will use our own chat
				isFloating: showPrimeChat.value,
				sslh: sslhStorage.value,
			};

			// Fallback data for PS script init method
			localStorage.setItem(
				'publisher',
				JSON.stringify({
					uuid: userId.value,
					sHash: userHash.value,
					appId: appId.value,
				})
			);
			try {
				window.PrimeSurveys('render', props);
			} catch (error) {
				throw new Error(
					'PrimeSurveys render fails, PrimeSurveys is not defined'
				);
			}

			if (cidStorage.value) {
				cidStorage.value = '';
			}

			if (sslhStorage.value) {
				sslhStorage.value = '';
			}
		});

	const onUserId = (cb: () => void) =>
		watch(
			userId,
			(userIdValue) => {
				if (userIdValue) {
					const interval = setInterval(() => {
						if (typeof window.PrimeSurveys === 'function') {
							clearInterval(interval);
							cb();
						}
					}, 50);
				}
			},
			{ immediate: true }
		);

	const unmountPrimeSurveys = () => {
		if (window.PrimeSurveys !== undefined) {
			window.PrimeSurveys('unmount');
		}
	};

	const providedData = {
		renderPrimeSurveys,
		unmountPrimeSurveys,
		integrationScriptLoading,
	};

	provide(primeSurveysInjection, providedData);

	return providedData;
};
